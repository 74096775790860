<template>
    <div id="all-padroesEventos" class="animated fadeIn">
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:1050px">
            <div slot="header">
            <div class="row">
              <div class="col-md-8" data-v-step="1">
                <strong>Lista de Usuários da Empresa</strong>
              </div>
              <div class="col-md-4" data-v-step="4" style="text-align:right;">
                <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "listausers.xls"
                   >
                  <CIcon name="cil-grid" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
          <b-alert show>
            <p><strong>{{ dadosEmpresa.grp_mnemonico }} ({{ dadosEmpresa.grp_ID }})</strong><br>
            <em>{{ dadosEmpresa.grp_descricao }}</em><br>
            Criação: {{ dadosEmpresa.grp_data_criacao | formataData }}<br>
            Validade: {{ dadosEmpresa.grp_data_validade | formataData }}</p>
          </b-alert>
        <p><router-link data-v-step="2" :to="{ name: 'Criar Usuário da Empresa', params: { id: this.$route.params.id } }" class="btn btn-primary">Criar usuário</router-link></p>

        <div class="form-group">
            <input type="text" name="search" v-model="usuarioSearch" placeholder="Procurar por nome do usuário" class="form-control" v-on:keyup="searchUsuario">
        </div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Telefone</th>
                <th data-v-step="5">Grupo</th>
                <th data-v-step="3">Ações</th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="usuarioItem in usuariosItens" :key="usuarioItem.id">
                    <td>{{ usuarioItem.name }}</td>
                    <td>{{ usuarioItem.email }}</td>
                    <td>{{ usuarioItem.phone }}</td>
                    <td>{{ osGrupos[usuarioItem.usr_ID] }}</td>
                    <td v-if="usuarioItem.is_active">
                        
                        <router-link :to="{name: 'Editar Usuário da Empresa', params: { id: usuarioItem.id }}" class="btn btn-success">Editar</router-link>&nbsp;
                        <button type="button" v-on:click="desativaUser(usuarioItem.id)" class="btn btn-danger">Desativar</button>
                        
                    </td>
                    <td v-else>
                      
                        <button type="button" v-on:click="ativaUser(usuarioItem.id)" class="btn btn-warning">Ativar</button>
                      
                    </td>
                </tr>
            </tbody>
        </table>
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Usuários">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/VHivaIj_2Qk?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Cadastro de Usuários Auxiliares',
          },
          content: `Aqui você cadastra outros usuários que poderão acessar a sua conta.`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Para adicionar um novo usuário, basta clicar no botão Criar usuário.',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Aqui você Edita um usuário (ex: troca a senha) ou desativa para que não possa mais acessar o sistema. A exclusão definitiva de usuários não é possível e necessita contato com o suporte da aplicação, caso seja necessário.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Para exportar a lista de usuário, agregando todas as informações acerca, basta clicar neste botão para gerar uma lista em Excel.',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="5"]',
          content: '<p>Os tipos (grupos) possíveis de usuários são:</p><p>GERENTE:<br> Tem acesso a todos as opções do Gera Preço, inclusive o menu de configurações.</p><p>COMERCIAL:<br> Tem acesso ao Gera Preço, exceto o menu de configurações.</p><p>ORÇAMENTISTA:<br> Apenas acessa a Tabela de Preços.</p>',
          params: {
            placement: 'bottom'
          }
        }
      ],
      usuarios: [],
      usuariosItens: [],
      originalUsuariosItens: [],
      usuarioSearch: '',
      sessao: '',
      osPerfis: [],
      osGrupos: [],
      dadosEmpresa: [],
      perm: [],
      w: '',
      matExcel: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    if (this.$session.get('grupo')) {
      this.w = ' AND (grp_ID = ' + this.$session.get('grupo') + ')'
    } else {
      this.w = ' AND (grp_ID = 8)'
    }
    this.verificaSessao()
  },
  filters: {
    formataData: function (num) {
      if (typeof num !== 'undefined') {
        const d = new Date(num)
        const ye = d.toLocaleString('pt-BR')
        return ye.split(',')[0]
      }
    }
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('CONFIGURACOES_USUARIOS'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.permissao_area().then(
          (response) => {
            this.perm = response
            this.listar('filtro_User_Grupo', 'name', '(gtipo_ID = 1)').then(
              (response) => {
                for (var i = 0; i < response.length; i++) {
                  this.osGrupos[response[i].usr_ID] = response[i].grp_mnemonico
                }
                this.listar('filtro_User_Grupo', 'name', '(gtipo_ID = 2)' + this.w).then(
                  (r) => {
                    this.usuariosItens = r
                    this.originalUsuariosItens = this.usuariosItens
                    for (var j = 0; j < r.length; j++) {
                      this.matExcel.push({
                        Tipo: r[j].grp_mnemonico,
                        Nome: r[j].name,
                        Primeiro_Nome: r[j].first_name,
                        Sobrenome: r[j].last_name,
                        Email: r[j].email,
                        Telefone: r[j].phone,
                        Data_Ultimo_Login: r[j].last_login_date,
                        Data_Criacao: r[j].created_date
                      })
                    }
                  },
                  (r) => {}
                )
                this.listar('gp_Grupo', 'grp_ID', '(grp_ID = ' + this.$session.get('grupo') + ')').then(
                  (r) => {
                    this.dadosEmpresa = r[0]
                    console.log(this.dadosEmpresa)
                  },
                  (r) => {
                    this.dadosEmpresa = []
                  }
                )
              }, (response) => {}
            )
          },
          (response) => {
            this.perm = response
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    ativaUser: function (idu) {
      this.atualizar('user', {is_active: 1}, idu, 'system').then(
        (response) => { document.location.reload() },
        (response) => {}
      )
    },
    desativaUser: function (idu) {
      this.atualizar('user', {is_active: 0}, idu, 'system').then(
        (response) => { document.location.reload() },
        (response) => {}
      )
    },
    searchUsuario: function () {
      if (this.usuarioSearch === '') {
        this.usuariosItens = this.originalUsuariosItens
        return
      }
      var searchedUsuariosItens = []
      for (var i = 0; i < this.originalUsuariosItens.length; i++) {
        var usuariosName = this.originalUsuariosItens[i]['name'].toLowerCase()
        if (usuariosName.indexOf(this.usuarioSearch.toLowerCase()) >= 0) {
          searchedUsuariosItens.push(this.originalUsuariosItens[i])
        }
      }
      this.usuariosItens = searchedUsuariosItens
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  }
}
</script>